import React from 'react';
import Layout from "../components/Layout"
import SEO from "../components/Seo"
import { MainContent } from "../styles/base"

const ProjetosPage = () => (
  <Layout>
    <SEO title="Projetos" />
    <MainContent>
      <h1>Projetos</h1>
    </MainContent>
  </Layout>
)

export default ProjetosPage;
